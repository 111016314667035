import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";
import hero1 from "../../assets/image/heros/shopping-centre.png";

interface Props {
  title?: string;
}

const SectionStyled = styled(Section)`
  background: url(${hero1}) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
`;

const HeroOverlay = styled(Section)`
  background: linear-gradient(90deg,rgba(13,51,63,.85) 0,transparent 100%);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const Hero: React.FC<Props> = ({ title, children, ...rest }) => {
  return (
    <>
      <SectionStyled
        pt={["110px!important", null, "170px!important"]}
        pb={["50px!important", null, "120px!important"]}
        {...rest}
      >
        <HeroOverlay />
        <Container>
          <Row>
            <Col lg="6">
              <div>
                <Title variant="hero" color="light">{title}</Title>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};
export default Hero;
